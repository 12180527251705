import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { characterEntered, fetchWordsAsync, WordsState } from './features/wordgrid/wordSlice';
import { Experiments, persistExperiments } from './app/experiments';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

store.dispatch(fetchWordsAsync());

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

declare global {
    interface Window {
        logWordsState: () => WordsState,
        logExperiments: () => object,
        toggleImageShare: () => void,
    }
}

window.toggleImageShare = () => {
    Experiments.imageSharingEnabled = !Experiments.imageSharingEnabled;
    persistExperiments();
}

window.logExperiments = () => Experiments;

window.logWordsState = () => store.getState().words;
window.addEventListener('keydown', (e: KeyboardEvent) => {
    store.dispatch(characterEntered(e.key));
});

if (
    process.env.NODE_ENV == null
    || process.env.NODE_ENV === 'development'
) {
    console.log('running in development mode');
}
