import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CharacterCorrectness } from '../wordgrid/constants';
import { characterEntered, selectGameState, selectTriedKeys } from '../wordgrid/wordSlice';
import styles from './Keyboard.module.css';

export interface KeyboardProps {

};

const KEYBOARD_ROWS = [
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
    ['enter', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '<'],
];

function specialKeysToKey(key: string) {
    if (key === '<') {
        return 'backspace';
    }
    return key;
}

function isSpecial(key: string) {
    switch (key) {
        case '<':
        case 'enter':
            return true;
        default:
            return false;
    }
}

function generateKeyClassNames(key: string, correctness?: CharacterCorrectness) {
    const classNames = [styles.key];
    if (isSpecial(key)) {
        classNames.push(styles.special_key);
    }
    switch (correctness) {
        case 'correct':
            classNames.push(styles.correct);
            break;
        case 'misplaced':
            classNames.push(styles.misplaced);
            break;
        case 'incorrect':
            classNames.push(styles.incorrect);
    }
    return classNames.join(' ');
}

export function Keyboard(props: KeyboardProps) {
    const dispatch = useAppDispatch();
    const gameState = useAppSelector(selectGameState);
    const triedKeys = useAppSelector(selectTriedKeys);

    if (gameState !== 'in_progress') {
        return null;
    }

    const rowElems = [];
    for (let rowIdx = 0; rowIdx < KEYBOARD_ROWS.length; rowIdx++) {
        const keyElements = [];
        const keys = KEYBOARD_ROWS[rowIdx];
        for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
            const key = keys[keyIdx];
            keyElements.push(
                <div
                    key={`key_${key}`}
                    className={generateKeyClassNames(key, triedKeys[key])}
                    onClick={() => {
                        dispatch(characterEntered(specialKeysToKey(key)));
                    }}
                >
                    <div>{key}</div>
                </div>
            );
        }
        rowElems.push(
            <div
                key={`row_${KEYBOARD_ROWS[rowIdx]}`}
                className={styles.keyboard_row}
            >
                {keyElements}
            </div>
        );
    }
    return (
        <div className={styles.keyboard}>
            {rowElems}
        </div>
    );
}
