import words_filtered from '../../assets/words_filtered_v2.txt';

export function fetchWordsList(): Promise<Array<string>> {
    const f = fetch(words_filtered)
        .then(async response => {
            if (response.status !== 200) {
                throw new Error('HttpError: ' + response.statusText);
            }
            return await response.text()
        })
        .then(text => text.split('\n'));

    f.catch(err => console.error(err));

    return f;
}
