import html2canvas from "html2canvas";
import { Experiments } from "../../app/experiments";
import { useAppSelector } from "../../app/hooks";
import { CharacterCorrectness, CURRENT_DATE } from "../wordgrid/constants";
import { GameState, selectGameState, selectTriedWords } from "../wordgrid/wordSlice"
import styles from './ShareBlock.module.css';

const DATE_STRING = new Intl.DateTimeFormat(
    'en-US',
    {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    }
).format(new Date(CURRENT_DATE));

const shareWords = () => {
    const wordGrid = document.getElementById('wordgrid');
    if (wordGrid == null) {
        return;
    }
    html2canvas(wordGrid)
        .then((canvas: HTMLCanvasElement) =>
            new Promise<Blob | null>(
                r => canvas.toBlob(r, 'text/jpeg')
            ))
        .then((blob: Blob | null) => {
            if (blob == null) {
                throw Error('blob is null');
            }
            const data = {
                files: [
                    new File(
                        [blob],
                        'results.jpg',
                        { type: blob.type }
                    )
                ]
            };
            if (navigator.canShare != null
                && !navigator.canShare(data)) {
                return;
            }
            navigator.share(data);
        })
        .catch(err => console.error(err));
}

const shareText = (
    gameState: GameState,
    words: Array<{ correctness: Array<CharacterCorrectness> }>,
) => () => {
    const resultString = gameState === 'win' ? `${words.length}/6` : 'LOSS';

    /**
     * For storage:
     *   ​🟥​🟧​🟨​🟩​🟦​🟪​⬛️​⬜️​🟫
     */
    const shareData = {
        text: `Wordler ${DATE_STRING}: ${resultString}\n`
            + words.map(({ correctness }) =>
                correctness.map(v => {
                    switch (v) {
                        case 'correct':
                            return '🟩​';
                        case 'incorrect':
                            return '⬛️​';
                        case 'misplaced':
                            return '🟨';
                        default:
                            throw Error(`${v} should not be in the correctness map`);
                    }
                }).join('')
            ).join('\n')
    };

    console.log(shareData.text);
    navigator.share(shareData)
        .catch(err => console.error(err));
}

const ShareTextButton = () => {
    const gameState = useAppSelector(selectGameState);
    const words = useAppSelector(selectTriedWords);

    if (navigator.canShare == null
        || !navigator.canShare({ text: 'test' })
    ) {
        return null;
    }
    return (
        <button
            className={styles.shareButton}
            onClick={shareText(gameState, words)}>
            Share Text
        </button>
    );
}

const ShareImageButton = () => {
    if (!Experiments.imageSharing
        || navigator.canShare == null
        || !navigator.canShare({
            files: [new File([], 'test.jpg', { type: 'image/jpeg' })],
        })
    ) {
        return null;
    }
    return (
        <button
            className={styles.shareButton}
            onClick={shareWords}>
            Share Image
        </button>
    );
}

export const ShareBlock = () => {
    const gameState = useAppSelector(selectGameState);

    if (gameState === 'loading'
        || gameState === 'in_progress') {
        return null;
    }

    if (navigator == null
        || navigator.canShare == null
        || navigator.share == null) {
        return null;
    }

    return (
        <div className={styles.shareBlock}>
            <ShareTextButton />
            <ShareImageButton />
        </div>
    );
}
