import { KEY_PRIOR_STATE, CURRENT_DATE, CharacterCorrectness } from "../features/wordgrid/constants";
import { GameState } from "../features/wordgrid/wordSlice";

export interface ActiveGameState {
    date: string,
    gameState: GameState,
    triedWords: Array<string>,
    triedKeys: { [key: string]: CharacterCorrectness },
}

export function loadPersistedGameState(): ActiveGameState | undefined {
    const gameJson = window.localStorage.getItem(KEY_PRIOR_STATE);
    if (gameJson == null) {
        return undefined;
    }
    const gameState = JSON.parse(gameJson);

    if (gameState.date !== CURRENT_DATE) {
        clearPersistedGameState();
        return undefined;
    }

    return {
        'date': CURRENT_DATE,
        'gameState': gameState.gameState,
        'triedWords': gameState.triedWords as Array<string>,
        'triedKeys': gameState.triedKeys,
    };
}

export function clearPersistedGameState() {
    window.localStorage.removeItem(KEY_PRIOR_STATE);
}

export function savePersistedGameState(state: ActiveGameState) {
    window.localStorage.setItem(
        KEY_PRIOR_STATE,
        JSON.stringify(state),
    );
}
