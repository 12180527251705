import { isDevelopment } from "./helpers";

const KEY_EXPERIMENTS = 'experiments';

const url = document.URL.split('?');
const params = url.length === 2 ? url[1] : '';
const searchParams = new URLSearchParams(params);
const paramMap: { [key: string]: any } = {};
searchParams.forEach((v: any, k: string) => { paramMap[k] = JSON.parse(v) });

const storedExperimentsStr = localStorage.getItem('experiments');
const storedExperiments =
    storedExperimentsStr != null
        ? JSON.parse(storedExperimentsStr)
        : {};

export const Experiments = {
    imageSharing: true,
    ...storedExperiments,
    ...paramMap
};

if (isDevelopment()) {
    console.log(Experiments);
}

export const persistExperiments = () => {
    localStorage.setItem(KEY_EXPERIMENTS, JSON.stringify(Experiments));
}

persistExperiments();
