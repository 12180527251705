import './App.css';
import { WordGrid } from './features/wordgrid/wordGrid';
import { useAppSelector } from './app/hooks';
import { selectGameState } from './features/wordgrid/wordSlice';
import { Keyboard } from './features/keyboard/Keyboard';
import { MAX_CHARS, MAX_ROWS } from './features/wordgrid/constants';
import { ShareBlock } from './features/share/ShareBlock';

export default function App(): JSX.Element {
    const gameState = useAppSelector(selectGameState);
    let gameClassnames = ['game'];
    if (gameState === 'win') {
        gameClassnames.push('win');
    } else if (gameState === 'loss') {
        gameClassnames.push('lose');
    }

    return (
        <div id='game' className={`${gameClassnames.join(' ')}`}>
            <header>WORDLE</header>
            <WordGrid
                maxCharsPerWord={MAX_CHARS}
                maxWords={MAX_ROWS} />
            <Keyboard />
            <ShareBlock />
        </div>
    );
}
