import React from 'react';
import styles from './WordGrid.module.css';
import { useAppSelector } from '../../app/hooks';
import { selectActiveWord, selectGameState, selectTargetWord, selectTriedKeys, selectTriedWords } from "./wordSlice";
import { CharacterCorrectness } from './constants';


interface WordGridProps {
    maxCharsPerWord: number,
    maxWords: number,
}

interface WordRowProps {
    rowId: number,
    charCount: number,
    word?: string,
    isActive: boolean,
    isAnswer?: true,
    correctness?: Array<CharacterCorrectness>,
}

function correctnessToClassname(correctness: CharacterCorrectness) {
    switch (correctness) {
        case 'correct':
            return styles.correct;
        case 'incorrect':
            return styles.incorrect;
        case 'misplaced':
            return styles.misplaced;
        case 'unknown':
            return styles.unknown;
        case 'fail':
            return styles.fail;
    }
}


function WordRow(props: WordRowProps): Array<JSX.Element> {
    const correctness = props.correctness;
    const chars = [];
    for (let i = 0; i < props.charCount; i++) {
        const keyStatus = correctness != null ? (correctness[i] ?? 'unknown') : 'unknown';
        const key = props.word?.charAt(i);

        const classes = [styles.character, correctnessToClassname(keyStatus)];
        if (props.isActive) {
            classes.push(styles.active);
        }
        if (props.isAnswer) {
            classes.push(styles.answer);
        }

        chars.push(
            <div
                key={`r${props.rowId}_c${i}`}
                className={classes.join(' ')}
            >
                {key}
            </div>);
    }
    return chars;
}

export function WordGrid(props: WordGridProps): JSX.Element | null {
    const triedWords = useAppSelector(selectTriedWords);
    const activeWord = useAppSelector(selectActiveWord);
    const targetWord = useAppSelector(selectTargetWord);
    const gameState = useAppSelector(selectGameState);
    const triedKeys = useAppSelector(selectTriedKeys);

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }

        window.addEventListener('resize', handleResize);

        return () =>
            window.removeEventListener(
                'resize',
                handleResize);
    });

    const rows = [];
    let rowsRendered = 0;

    // render the previously tried words
    for (let i = 0; i < triedWords.length; i++) {
        rowsRendered++;
        rows.push(
            WordRow({
                rowId: rowsRendered,
                charCount: props.maxCharsPerWord,
                isActive: false,
                word: triedWords[i].word,
                correctness: triedWords[i].correctness,
            }));
    }

    if (gameState === 'loss') {
        if (targetWord == null) {
            throw Error('targetWord is undefined');
        }
        rowsRendered++;
        rows.push(
            WordRow({
                rowId: rowsRendered,
                charCount: props.maxCharsPerWord,
                isActive: false,
                isAnswer: true,
                word: targetWord,
                correctness:
                    targetWord?.split('')
                        .map(c => {
                            switch (triedKeys[c]) {
                                case 'correct':
                                    return 'correct';
                                case 'misplaced':
                                    return 'misplaced';
                                default:
                                    return 'fail';
                            }
                        }),
            }));
    } else if (gameState === 'in_progress') {
        rowsRendered++;
        rows.push(
            WordRow({
                rowId: rowsRendered,
                charCount: props.maxCharsPerWord,
                isActive: true,
                word: activeWord,
            }));

        // render the future rows
        for (let i = rowsRendered; i < props.maxWords; i++) {
            rowsRendered++;
            rows.push(
                WordRow({
                    rowId: rowsRendered,
                    charCount: props.maxCharsPerWord,
                    isActive: false,
                    word: '',
                })
            );
        }
    }

    const expectedKeyboardHeight = (gameState !== 'in_progress') ? 120 : 150;
    const expectedTopbarHeight = 40;
    const expectedVerticalSpacing = 40;

    // 230 comes from height of the top-bar + keyboard + whitespace
    const containerHeight =
        dimensions.height
        - expectedKeyboardHeight
        - expectedTopbarHeight
        - expectedVerticalSpacing;
    // 30 comes from margins of app
    const containerWidth = Math.min(500, dimensions.width - 20);

    let gridWidth, gridHeight;
    if (containerWidth > containerHeight * 5 / rowsRendered) {
        gridWidth = containerHeight * 5 / rowsRendered;
        gridHeight = containerHeight;
    } else if (containerHeight > containerWidth * rowsRendered / 5) {
        gridWidth = containerWidth;
        gridHeight = containerWidth * rowsRendered / 5;
    }

    const classes = [styles.wordgrid];

    return (
        <div
            className={styles.wordgrid_container}
            id='wordgrid-container'
            style={{
                'width': containerWidth,
                'height': containerHeight,
            }}
        >
            <div
                id='wordgrid'
                className={classes.join(' ')}
                style={{
                    width: gridWidth,
                    height: gridHeight,
                    margin: '0 auto',
                    gridTemplateRows: `repeat(${rowsRendered}, 1fr)`
                }}
            >
                {rows}
            </div>
        </div >
    )
}
