import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadPersistedGameState, savePersistedGameState } from "../../app/persistence";
import { AppThunk, RootState } from "../../app/store";
import { CharacterCorrectness, CURRENT_DATE } from "./constants";
import { fetchWordsList } from "./wordsApi";

export type WordFetchRequestStatus = 'idle' | 'loading' | 'failed' | 'complete';
export type GameState = 'loading' | 'in_progress' | 'win' | 'loss';

export interface WordsState {
    wordListFetchStatus: WordFetchRequestStatus,
    triedWords: Array<{ word: string, correctness: Array<CharacterCorrectness> }>,
    targetWord?: string,
    wordDictionarySet: { [key: string]: boolean },
    activeRow: number,
    activeWord: string,
    targetWordIndexMap: { [key: string]: { [key: number]: boolean } },
    gameState: GameState,
    triedKeys: { [key: string]: CharacterCorrectness }
};

const digest = async (data: string) => {
    const devSalt = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? "_development"
        : '';

    let buffer: Uint8Array = new TextEncoder().encode(data + devSalt);

    return crypto.subtle != null
        ? crypto.subtle.digest('SHA-256', buffer)
        : buffer;
};

export async function generateIndex(indexBase: string, possibleWordCount: number) {
    const hash = await digest(indexBase);
    const hashInt = Array.from(new Uint32Array(hash)).reduce(
        (prev, current) => prev ^ current
    );

    return Math.abs(hashInt) % possibleWordCount;
}


const INITIAL_STATE: WordsState = {
    wordListFetchStatus: 'idle',
    triedWords: [],
    wordDictionarySet: {},
    activeRow: 0,
    activeWord: '',
    targetWordIndexMap: {},
    gameState: 'loading',
    triedKeys: {}
};

/**
 * This is a little complex because we want to provide signal on how many
 * of each char are in the word.  If there's only one 'x' and it's marked correctly
 * we don't want to say that another 'x' placed elsewhere is misplaced.  This is also
 * true if there are multiple 'x's in the word.
 *
 * We do this by iterating over the array twice.  The first time marks all the correct
 * characters and counts the number of each.  The second then marks the misplaced
 * characters, but no more than are in the word.
 *
 * @param {string} wordAttempt
 * @param {object} charToIndexesMap
 * @returns array representing which chars are correct, misplaced, incorrect
 */
export function diff(
    wordAttempt: string,
    charToIndexesMap: { [key: string]: { [key: number]: boolean } },
): Array<CharacterCorrectness> {
    const result: Array<CharacterCorrectness> = Array(wordAttempt.length).fill('incorrect', 0);

    const markedCorrect: { [key: string]: number } = {};
    for (let i = 0; i < wordAttempt.length; i++) {
        const char = wordAttempt[i];

        if (charToIndexesMap[char] != null && charToIndexesMap[char][i]) {
            result[i] = 'correct';
            markedCorrect[char] = (markedCorrect[char] ?? 0) + 1;
        }
    }

    const markedMisplaced: { [key: string]: number } = {};
    for (let i = 0; i < wordAttempt.length; i++) {
        if (result[i] !== 'incorrect') {
            // already marked as CORRECT
            continue;
        }

        const char = wordAttempt[i];

        if (charToIndexesMap[char] != null) {
            // It's in the word but not CORRECT
            const countInWord = Object.keys(charToIndexesMap[char]).length;
            const countAlreadyMarkedCorrect = markedCorrect[char] ?? 0;
            const countAlreadMarkedMisplaced = markedMisplaced[char] ?? 0;
            const countRemainingToMark = countInWord - countAlreadyMarkedCorrect - countAlreadMarkedMisplaced;

            if (countRemainingToMark > 0) {
                result[i] = 'misplaced';
                markedMisplaced[char] = (markedMisplaced[char] ?? 0) + 1;
            }
        }
    }

    return result;
}

export function wordToMap(word: string) {
    const output: { [key: string]: { [key: number]: boolean } } = {};

    for (let i = 0; i < word.length; i++) {
        const c = word[i];
        if (output[c] == null) {
            output[c] = {};
        }
        output[c][i] = true;
    }

    return output;
}

export const fetchWordsAsync = createAsyncThunk(
    'words/fetchWordsList',
    async () => {
        const list = await fetchWordsList();
        const index = await generateIndex(CURRENT_DATE, list.length);
        return {
            'list': list,
            'index': index,
        };
    },
);

function toStringSet(list: Array<string>) {
    const set: { [key: string]: boolean } = {};
    for (let i = 0; i < list.length; i++) {
        set[list[i]] = true;
    }
    return set;
}

export const characterEntered = (key: string): AppThunk => (
    dispatch,
    getState,
) => {
    dispatch(_characterEntered({
        'key': key.toLowerCase(),
        priorState: getState().words,
    }));
};

export const wordSlice = createSlice({
    initialState: INITIAL_STATE,
    name: 'wordSlice',
    reducers: {
        _characterEntered: (
            draftState,
            action: PayloadAction<{
                key: string,
                priorState: WordsState,
            }>) => {
            const { key, priorState } = action.payload;
            const {
                gameState,
                activeWord,
                targetWord,
                wordDictionarySet,
                targetWordIndexMap,
                activeRow,
                triedWords,
                triedKeys,
            } = priorState;

            if (gameState !== 'in_progress') {
                return;
            }

            if (key === 'backspace') {
                draftState.activeWord = activeWord.slice(0, -1);
            } else if (
                key === 'enter'
                && activeWord.length === 5
                && wordDictionarySet[activeWord] != null
            ) {
                if (targetWord == null) {
                    throw new Error("target word isn't defined");
                }
                const diffResult = diff(activeWord, targetWordIndexMap);
                const newTriedWords = [...triedWords, { word: activeWord, correctness: diffResult }];
                draftState.triedWords = newTriedWords;

                const newTriedKeys = {
                    ...triedKeys,
                };
                for (let i = 0; i < activeWord.length; i++) {
                    const priorCorrectness = newTriedKeys[activeWord[i]];
                    if (
                        priorCorrectness == null
                        || (priorCorrectness === 'incorrect' && diffResult[i] === 'correct')
                        || (priorCorrectness === 'misplaced' && diffResult[i] === 'correct')
                    ) {
                        newTriedKeys[activeWord[i]] = diffResult[i];
                    }
                }
                draftState.triedKeys = newTriedKeys;

                let newGameState: GameState = gameState;
                if (activeWord === targetWord) {
                    newGameState = 'win';
                } else if (activeRow === 5) {
                    newGameState = 'loss';
                }
                draftState.gameState = newGameState;
                draftState.activeWord = '';
                draftState.activeRow++;

                savePersistedGameState({
                    'date': CURRENT_DATE,
                    'triedWords': newTriedWords.map(word => word.word),
                    'triedKeys': newTriedKeys,
                    'gameState': newGameState,
                });
            } else if (key.length === 1 && activeWord.length < 5) {
                draftState.activeWord += key;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchWordsAsync.pending,
                status => { status.wordListFetchStatus = 'loading'; }
            )
            .addCase(
                fetchWordsAsync.fulfilled,
                (status, action) => {
                    const { list, index } = action.payload;
                    status.wordListFetchStatus = 'complete';
                    status.gameState = 'in_progress';
                    status.targetWord = list[index];
                    status.wordDictionarySet = toStringSet(list);

                    const targetWordMap = wordToMap(list[index])
                    status.targetWordIndexMap = targetWordMap;

                    const persistedState = loadPersistedGameState();
                    if (persistedState != null) {
                        const { triedWords, triedKeys, gameState } = persistedState;
                        const triedWordsState = triedWords.map(word => {
                            return {
                                'word': word,
                                'correctness': diff(word, targetWordMap),
                            }
                        });
                        status.triedWords = triedWordsState;
                        status.triedKeys = triedKeys;
                        status.gameState = gameState;
                        status.activeRow = triedWords.length;
                    }
                });
    },
});

const { _characterEntered } = wordSlice.actions;

export const selectTriedWords = (state: RootState) => state.words.triedWords;
export const selectTargetWordMap = (state: RootState) => state.words.targetWordIndexMap;
export const selectWordsList = (state: RootState) => state.words.wordDictionarySet;
export const selectWordListFetchStatus = (state: RootState) => state.words.wordListFetchStatus;
export const selectActiveRow = (state: RootState) => state.words.activeRow;
export const selectTargetWord = (state: RootState) => state.words.targetWord;
export const selectActiveWord = (state: RootState) => state.words.activeWord;
export const selectGameState = (state: RootState) => state.words.gameState;
export const selectTriedKeys = (state: RootState) => state.words.triedKeys;

export default wordSlice.reducer;
